import type { CpmDataBundle, KeyedMappingDefinition } from '@dx-ui/cpm-sdk';
import type { AuthClient } from '@dx-ui/framework-auth-provider';
import { getOneLinkProps, serverSideOneLinkConfig } from '@dx-ui/framework-i18n';
import { getServerSideSafeLanguage, makeServerQueryClient } from '@dx-ui/framework-react-query';
import { type QueryClient } from '@tanstack/react-query';
import type { Request as ExpressRequest } from 'express';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { REACT_QUERY_CONFIG } from '../../config/react-query';
import nextI18nextConfig from '../../next-i18next.config';
import type {
  Brand_CountriesQuery,
  Brand_HiltonForBusinessQuery,
  Brand_NonMemberQuery,
  FeatureTogglesQuery,
  HonorsTierBenefitsQuery,
} from '@dx-ui/queries-dx-guests-ui';
import { auth } from '../auth';
import { getSupportedLanguages } from '../featureToggles';
import { getDehydratedQueryState } from '../getDehydratedState';
import { getSpeedBumpFeatureConfig } from '@dx-ui/osc-speedbump';
import { getBrandRefreshProps } from '@dx-ui/framework-theme';
import { serverSideFeatureTogglesQuery } from '../../queries/generated/react-query';
import { featureFlags } from '../constants/featureFlags';
import type { FeatureTogglesQueryData } from '@dx-ui/utilities-feature-toggles-configs';
import { logError, logWarning } from '@dx-ui/framework-logger';
import { generateError } from '@dx-ui/utilities-generate-error';

export const QUERY_LANGUAGE_KEY = ['queryLanguage'];

type Metrics = {
  trackingData: {
    pageView: string;
    pageData: {
      pageDetail?: string[];
      pageName?: string;
      pageType: string;
      subSection?: string;
      subSubSection?: string;
      pageCategory?: string;
      primaryCategory?: string;
      isCmsPage?: boolean;
      expType?: string;
    };
  };
};

type PageProps = {
  countriesData?: Brand_CountriesQuery;
  cmsData?: Brand_HiltonForBusinessQuery;
  cpmData?: CpmDataBundle<KeyedMappingDefinition>;
  env?: unknown;
  featureToggles?: FeatureTogglesQueryData['featureToggles'];
  forwardUrl?: string;
  hasCustomCanonicalUrl?: boolean;
  layout?: Record<string, string | null | boolean>;
  memberBenefitsData?: HonorsTierBenefitsQuery;
  metrics?: Metrics;
  nonMemberData?: Brand_NonMemberQuery;
  remoteIp?: string;
};

interface CoreServerSideProps {
  authClient?: AuthClient;
  context: GetServerSidePropsContext;
  dehydratedOriginalOpNames?: string[];
  isOneLinkPage?: boolean;
  namespaces: string[];
  pageProps: PageProps;
  queryClient?: QueryClient;
  includeConfigRules?: boolean;
}

async function coreServerSideProps({
  dehydratedOriginalOpNames,
  authClient: authClientProp,
  context,
  namespaces,
  pageProps,
  queryClient: queryClientProp,
  includeConfigRules,
}: CoreServerSideProps) {
  const authClient = authClientProp || auth(context.req as unknown as Request);
  const queryClient =
    queryClientProp ||
    makeServerQueryClient({
      ...REACT_QUERY_CONFIG,
      serverResponse: context.res,
      authClient,
    });

  const originalLocale = getServerSideSafeLanguage(context);
  const oneLinkConfig = await serverSideOneLinkConfig(queryClient).catch((error) => {
    logError('SERVER_SIDE_PROPS', generateError(error), `Error in ${serverSideOneLinkConfig.name}`);
    return null;
  });
  const appNameConfig: string = REACT_QUERY_CONFIG.appName;
  const oneLinkProps = getOneLinkProps(
    originalLocale || context.locale,
    appNameConfig,
    context.resolvedUrl,
    oneLinkConfig
  );
  const [translations, { configRules, supportedLanguages }, featureTogglesData] = await Promise.all(
    [
      serverSideTranslations(oneLinkProps.sstLocale, namespaces, nextI18nextConfig).catch(
        (error) => {
          logError(
            'SERVER_SIDE_PROPS',
            generateError(error),
            `Error in ${serverSideTranslations.name}`
          );

          return {};
        }
      ),
      getSupportedLanguages({
        client: queryClient,
        path: (context.req as ExpressRequest)?.path,
        locales: context.locales,
      }),
      serverSideFeatureTogglesQuery(queryClient, {
        flags: featureFlags,
      }).catch((error) => {
        logWarning(
          'SERVER_SIDE_PROPS',
          generateError(error),
          `Error in ${serverSideFeatureTogglesQuery.name} | ${featureFlags}`
        ); // eslint-disable-line no-console
        return { featureToggles: [] } as FeatureTogglesQuery;
      }),
      getSpeedBumpFeatureConfig(queryClient).catch((error) => {
        logError(
          'SERVER_SIDE_PROPS',
          generateError(error),
          `Error in ${getSpeedBumpFeatureConfig.name}`
        );
      }),
    ]
  );
  queryClient.setQueryData(QUERY_LANGUAGE_KEY, oneLinkProps.sstLocale);
  const featureToggles = [
    ...(pageProps?.featureToggles || []),
    ...featureTogglesData.featureToggles,
  ];

  return {
    ...pageProps,
    ...translations,
    ...oneLinkProps,
    ...(includeConfigRules && { configRules }),
    ...getBrandRefreshProps({ featureToggles }),
    supportedLanguages,
    featureToggles,
    dehydratedState: getDehydratedQueryState({
      queryClient,
      dehydratedOriginalOpNames,
    }),
  };
}

type IncludedGuestIdProp = { guestId: number };
type OptionalGuestIdProp = { guestId?: never };

type CoreReturnProps = Awaited<ReturnType<typeof coreServerSideProps>>;

type ServerSideProps =
  | (CoreServerSideProps & OptionalGuestIdProp)
  | (CoreServerSideProps & IncludedGuestIdProp);

type ReturnGuestIdProp<T extends ServerSideProps> = T extends { guestId: number }
  ? IncludedGuestIdProp
  : OptionalGuestIdProp;

export async function serverSideProps<T extends ServerSideProps>({ guestId, ...coreProps }: T) {
  const props = await coreServerSideProps(coreProps).catch((error) => {
    logError('SERVER_SIDE_PROPS', generateError(error), `Error in ${coreServerSideProps.name}`);
    return {};
  });

  return {
    props: {
      ...(!!guestId && { guestId }),
      ...props,
    } as CoreReturnProps & ReturnGuestIdProp<T>,
  };
}
