export const Goals = {
  CreditCardApplyNow: 'g-credit-card-apply-now',
  HonorsApplyNow: 'g-honors-apply-now',
  SurpassApplyNow: 'g-surpass-apply-now',
  AspireApplyNow: 'g-aspire-apply-now',
  BusinessApplyNow: 'g-business-apply-now',
  CreditCardTermsAndConditions: 'g-credit-card-terms-and-conditions',
  SuccessfulHonorsEnrollment: 'g-successful-honors-enrollment',
  FindAHotel: 'g-8xd5mdahgj',
  FindAHotelMCLP: 'FindAHotel-MCLP',
  LearnMoreCtaClick: 'g-LearnMoreCtaClick',
  TierTabSelection: 'g-tierTabSelection',
  EditSearchWidget: 'g-EditGuestSearchWidget',
  BookYourNextGetaway: 'g-book-your-next-getaway',
  PopUpCloseClick: 'g-x-exit',
  ExploreMoreRatesCTATile1: 'g-explore-more-rates-cta-tile-1',
  ExploreMoreRatesCTATile2: 'g-explore-more-rates-cta-tile-2',
  ExploreMoreRatesCTATile3: 'g-explore-more-rates-cta-tile-3',
  ExploreMoreRatesCTATile4: 'g-explore-more-rates-cta-tile-4',
  ExploreMoreRatesCTA: 'g-explore-more-rates-cta',
  JoinHiltonHonorsCTAClick: 'JoinHiltonHonorsCTAClick',
  CombinedClickOnAllTiles: 'g-combined-click-on-all-tiles',
  HeadLinerLinkClick: 'g-headliner-link-click',
} as const;
