import { useConductricsSelection } from '@dx-ui/framework-conductrics';
import { useConductrics } from './useConductrics';

export const useNaaActivityBanner = () => {
  useConductrics();
  const { isLoaded, selection } = useConductricsSelection('a-NIgyzvqk1ltk');
  return {
    NAA_ActivityOfferBanner: isLoaded && selection?.choice === 'B',
  };
};
