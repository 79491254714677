import { allEnv } from '@dx-ui/framework-env';

import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import type { GetServerSidePropsContext } from 'next';
import { getServerSideSafeLanguage } from '@dx-ui/framework-react-query';
import type { AuthClient } from '@dx-ui/framework-auth-provider';
import { serverSideGuest_GetHHonorsNumberQuery } from '@dx-ui/queries-dx-guests-ui';
import type { QueryClient } from '@tanstack/react-query';
import { logError } from '@dx-ui/framework-logger';
import { generateError } from '@dx-ui/utilities-generate-error';

const { OHW_BASE_URL } = allEnv();

/**
 * Get base application url.
 */
const getUrlHost = () => new URL(OHW_BASE_URL).origin;

/**
 * Get full path for asset located in dx-assets-ui.
 * @param path - Asset path ('/modules/assets/fonts/loew/black.woff2')
 */
export const getAssetUrl = (path: string) => getExternalUrl(`/modules/assets${path}`);

/**
 * @param path - Page path ('/en/locations/')
 */
const getExternalUrl = (path: string) => new URL(path, getUrlHost()).toString();

/**
 * Get login url
 * @param forwardPageURI
 * @param language
 */
const getLoginUrl = ({
  language,
  forwardPageURI,
}: {
  language: string;
  forwardPageURI?: string | null;
}) => {
  const loginUrl = new URL(getExternalUrl(`/${language}/hilton-honors/login/`));
  if (forwardPageURI) {
    const refererUrl = new URL(forwardPageURI, getUrlHost());
    const { searchParams } = refererUrl;
    searchParams.set('forwardPageURI', forwardPageURI);
    searchParams.delete('language');
    loginUrl.search = searchParams.toString();
  }

  return loginUrl;
};

/**
 * Get relative login url
 */
export const getLoginRedirectUrl = (
  resolvedUrl: string,
  query: ParsedUrlQuery,
  locale: SupportedLanguage
) => {
  const redirectSearchQuery = new URLSearchParams(query as Record<string, string>);
  redirectSearchQuery.set('forwardPageURI', encodeURIComponent(`/${locale}${resolvedUrl}`));
  return `${OHW_BASE_URL}${locale}/hilton-honors/login/?${redirectSearchQuery.toString()}`;
};

export const getServerSideLoginRedirectUrl = (context: GetServerSidePropsContext) => {
  const originalLocale = getServerSideSafeLanguage(context);
  const language = (originalLocale || context.locale) as SupportedLanguage;
  return getLoginRedirectUrl(context.resolvedUrl, context.query, language);
};

const getGuestIdAndRedirectFn = async ({
  authClient,
  locale,
  queryClient,
  redirectUrl,
}: {
  authClient: AuthClient;
  locale: SupportedLanguage;
  queryClient: QueryClient;
  redirectUrl: string;
}) => {
  let guestId = authClient.getGuestId();

  if (guestId) {
    const data = await serverSideGuest_GetHHonorsNumberQuery(queryClient, {
      language: locale,
      guestId,
    }).catch((error) => {
      logError(
        'URL_BUILDER',
        generateError(error),
        `Error in ${serverSideGuest_GetHHonorsNumberQuery.name}`
      );
      return null;
    });
    if (!data?.guest?.hhonors?.hhonorsNumber) {
      guestId = null;
    }
  }

  return {
    guestId,
    logoutAndRedirect: async () => {
      await authClient.logout();
      return {
        redirect: {
          permanent: false,
          destination: redirectUrl,
        },
      };
    },
  };
};

export { getExternalUrl, getGuestIdAndRedirectFn, getLoginUrl, getUrlHost };
