import { allEnv, env } from '@dx-ui/framework-env';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import * as React from 'react';

import { getGuestFirstName, useAuth } from '@dx-ui/framework-auth-provider';
import { Header as OSCHeader, useFilterMegaNavExperiments } from '@dx-ui/osc-header';
import { Speedbump, makeAnchorEventTarget, useSpeedbump } from '@dx-ui/osc-speedbump';

import {
  ENABLE_JAPANESE_CO_BRAND_PAGE_LINK,
  featureFlags,
} from '../../utils/constants/featureFlags';
import { getLoginRedirectUrl } from '../../utils/helpers/urlBuilder';

import type { HeaderProps, TDrawerItem } from '@dx-ui/osc-header';

import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import { logError } from '@dx-ui/framework-logger';
import type { LoginResponse } from '@dx-ui/osc-login';
import { generateError } from '@dx-ui/utilities-generate-error';
import { useIsClient } from 'usehooks-ts';
import { useFeatureTogglesQuery, useNavigationQuery } from '@dx-ui/queries-dx-guests-ui';
import type { Maybe } from '../../types/generated/types';
import { isFeatureEnabled } from '../../utils/featureToggles';

type Props = {
  forwardPageURI?: string | null;
  hasGoHiltonHeader?: boolean;
};

const { APP_NAME, OHW_BASE_URL } = allEnv();

export type NavLink = {
  adaDescription?: Maybe<string>;
  label?: Maybe<string>;
  url?: Maybe<string>;
  secondaryLinks: Array<{
    label?: Maybe<string>;
    adaDescription?: Maybe<string>;
    campaignId?: Maybe<string>;
    isNewWindow?: Maybe<boolean>;
    url?: Maybe<string>;
  }>;
};

const getCreditCardUrl = (link: NavLink, locale: string, showNewJACobrandLink: boolean) => {
  switch (locale) {
    case 'ja':
      link.url = showNewJACobrandLink
        ? `${env('OHW_BASE_URL')}ja/hilton-honors/credit-cards/`
        : 'https://www.americanexpress.com/ja-jp/credit-cards/card-types/cobrand-hilton-mclp/index.html';
      return link;
    case 'de':
      link.url = 'https://www.hiltonhonorscreditcard.de/';
      return link;
    default:
      return link;
  }
};

const useActiveNavLinks = ({
  brandCode,
  language,
  onShow,
}: {
  brandCode: string;
  language: string;
  onShow: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => boolean;
}) => {
  const { data } = useNavigationQuery({ brandCode, language });
  const { data: featureToggleData } = useFeatureTogglesQuery({ flags: featureFlags });
  const showNewJACobrandLink = isFeatureEnabled(
    featureToggleData?.featureToggles || [],
    ENABLE_JAPANESE_CO_BRAND_PAGE_LINK
  );
  const handleCreditCardClick = (event) => {
    if (['ja', 'de'].includes(language)) {
      return;
    }
    onShow(event);
  };

  if (data?.brand?.header?.sections.length === 1) {
    return data?.brand?.header?.sections?.[0]?.links.map((link) => {
      return {
        label: link.label,
        link: { ...link, onClick: onShow },
        subMenu: link.secondaryLinks.map((secondaryLink) => {
          return { label: secondaryLink.label, link: { ...secondaryLink, onClick: onShow } };
        }),
      };
    }) as TDrawerItem[];
  } else if (data?.brand?.header?.sections.length && data?.brand?.header?.sections.length > 1) {
    return data?.brand?.header?.sections?.flatMap((section) => {
      return section.links.map((link) => {
        return {
          label: link.label,
          link: link?.url
            ? link?.url.includes('credit-cards')
              ? {
                  ...getCreditCardUrl(link, language, showNewJACobrandLink),
                  onClick: handleCreditCardClick,
                }
              : { ...link, onClick: onShow }
            : { ...link, onClick: onShow },
          subMenu: link.secondaryLinks.map((secondaryLink) => {
            return { label: secondaryLink.label, link: { ...secondaryLink, onClick: onShow } };
          }),
        };
      });
    }) as TDrawerItem[];
  }
};

export const Header = (props: Props) => {
  const [user, setUser] = React.useState<HeaderProps['user']>();
  const isClient = useIsClient();
  const { locale = 'en', asPath, query } = useRouter();
  const { forwardPageURI } = props;
  const [t] = useTranslation('header');
  const { isAuthenticated, logout, login, guestInfo } = useAuth();

  const { isVisible, onContinue, onHide, onShow } = useSpeedbump(locale, APP_NAME);

  const activeNavItems = useActiveNavLinks({ brandCode: 'WW', language: locale, onShow });
  const navItems = useFilterMegaNavExperiments(activeNavItems);

  const brandLink =
    isClient && isAuthenticated
      ? `/${locale}/hilton-honors/guest/my-account/`
      : `/${locale}/hilton-honors/`;

  React.useEffect(() => {
    setUser(
      guestInfo
        ? {
            name: getGuestFirstName({ guestInfo }),
            honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
            honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
            honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
            hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber || undefined,
          }
        : undefined
    );
  }, [guestInfo]);

  const onSignInAttempt = React.useCallback(
    async (resp: LoginResponse) => {
      if (resp?.data) {
        await login(resp).catch((error) => {
          logError('HEADER', generateError(error), 'error on login.');
        });
        if (forwardPageURI) {
          const fakeEventTarget = makeAnchorEventTarget(forwardPageURI);
          if (!onShow(fakeEventTarget)) {
            onContinue();
            if (forwardPageURI) window.location.assign(forwardPageURI);
          }
        }
      }
    },
    [login, forwardPageURI, onShow, onContinue]
  );

  const onSignOut = React.useCallback(async () => {
    await logout()
      .then(() => {
        if (asPath.includes('hgv-max')) {
          const hgvRedirectUrl = `/${locale ?? 'en'}/hgv-max/`;
          window.location.assign(hgvRedirectUrl);
        }

        if (asPath.includes('go-hilton')) {
          const goHiltonRedirectUrl = `/${locale ?? 'en'}/go-hilton/`;
          window.location.assign(goHiltonRedirectUrl);
        }

        if (/hilton-honors\/guest/.test(asPath)) {
          const redirectUrl = /guest\/points/.test(asPath)
            ? `/${locale ?? 'en'}/hilton-honors/points/`
            : getLoginRedirectUrl(asPath, query, locale as SupportedLanguage);
          window.location.assign(redirectUrl);
        }
      })
      .catch((error) => {
        logError('HEADER', generateError(error), 'error on logout.');
      });
  }, [asPath, locale, logout, query]);

  return (
    <>
      <OSCHeader
        showNewHeader={true}
        brand={{
          url: brandLink,
          name: t('hiltonHonors'),
          code: 'HH',
          onClick: onShow,
        }}
        loginOptions={{
          options: {
            showCreatePassword: true,
            forgetInfoLink: {
              url: `${OHW_BASE_URL}${locale}/hilton-honors/login/forgot-information/`,
            },
            secureInfoLink: {
              url: `${OHW_BASE_URL}${locale}/p/global-privacy-statement/`,
            },
            joinLink: {
              url: `${OHW_BASE_URL}${locale}/hilton-honors/join/?ocode=JHTNW`,
            },
          },
        }}
        isFluid={true}
        megaNavLinks={navItems}
        onSignOut={onSignOut}
        onSignInAttempt={onSignInAttempt}
        user={user}
      />
      <Speedbump isShowing={isVisible} onClose={onHide} onContinue={onContinue} />
    </>
  );
};
