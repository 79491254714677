import type { Guest } from '@dx-ui/gql-types';

export type EnhancedSecurityMethod = {
  hasValidatedContactMethod: boolean;
  deliveryMethod: 'email' | 'sms' | null;
  type: 'phone' | 'email' | null;
  displayValue: Guest['personalinfo']['phones'][number]['phoneNumberMasked'] | null;
  deliveryId: Guest['personalinfo']['phones'][number]['phoneId'] | null;
};

export const useEnhancedSecurityMethod = (
  personalInfo: Partial<Guest['personalinfo']> | undefined | null
): EnhancedSecurityMethod => {
  if (!personalInfo || (!personalInfo?.emails && !personalInfo?.phones))
    return {
      hasValidatedContactMethod: false,
      type: null,
      displayValue: null,
      deliveryId: null,
      deliveryMethod: null,
    };

  if (personalInfo?.phones) {
    const validatedPhone = personalInfo?.phones?.find(({ validated }) => validated);
    if (validatedPhone)
      return {
        hasValidatedContactMethod: true,
        type: 'phone',
        displayValue: validatedPhone.phoneNumberMasked,
        deliveryId: validatedPhone?.phoneId,
        deliveryMethod: 'sms',
      };
  }

  if (personalInfo?.emails) {
    const validatedEmail = personalInfo?.emails?.find(({ validated }) => validated);
    if (validatedEmail)
      return {
        hasValidatedContactMethod: true,
        type: 'email',
        displayValue: validatedEmail.emailAddressMasked,
        deliveryId: validatedEmail?.emailId,
        deliveryMethod: 'email',
      };
    const preferredEmail = personalInfo?.emails?.find(({ preferred }) => preferred);
    // Should NEVER be false
    if (preferredEmail)
      return {
        hasValidatedContactMethod: false,
        type: 'email',
        displayValue: preferredEmail.emailAddressMasked,
        deliveryId: null,
        deliveryMethod: null,
      };
  }

  return {
    hasValidatedContactMethod: false,
    type: null,
    displayValue: null,
    deliveryId: null,
    deliveryMethod: null,
  };
};
